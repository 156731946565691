import React from "react"
import { htmlRenderOptions } from "../utils/htmlRenderOptions"
import parse from "html-react-parser"

const LinksList = props => {
    return(
        <div className="mx-3 lg:mx-7">
          <div className="font-futurastd_heavy uppercase text-lg lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
            {" "}
            {props.title}{" "}
          </div>
          {props.list.map((item, i) => (
            <div key={i} className="text-xs pb-6 pt-3 font-futurastd_book uppercase">
              {parse(item.html, htmlRenderOptions)}
            </div>
          ))}
        </div>
    )

}

export default LinksList

import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import EventHeroComponent from "../components/EventHeroComponent"
import { withTrans } from "../withTrans"
import LinksList from "../components/LinksList"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!, $id: ID) {
    detailsItem: gcms {
      programmes(locales: $locale, orderBy: start_ASC) {
        id
        title
        description
        slug
        cardImage(locales: en) {
          url
        }
        start
        end
        typeOfProgram
      }
      programme(locales: $locale, where: { id: $id }) {
        id
        title
        subtitle
        longDescription {
          html
        }
        sliderImages(locales: en) {
          url
        }
        links {
          html
        }
        explore {
          html
        }
        start
        end
      }
    }
  }
`

class ProgrammeDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      programmeDetailsData: this.props.data.detailsItem.programme,
      programmesArray: this.props.data.detailsItem.programmes,
      nextEventLink: "",
      previousEventLink: "",
    }
    this.getTitle = this.getTitle.bind(this)
  }
  getTitle() {
    if (
      this.state.programmeDetailsData.end === null ||
      this.state.programmeDetailsData.end ===
        this.state.programmeDetailsData.start
    ) {
      return new Date(this.state.programmeDetailsData.start).toLocaleDateString(
        this.props.language,
        {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        }
      )
    } else {
      return (
        new Date(this.state.programmeDetailsData.start).toLocaleDateString() +
        " - " +
        new Date(this.state.programmeDetailsData.end).toLocaleDateString()
      )
    }
  }

  componentDidMount() {
    const { programmesArray, programmeDetailsData } = this.state
    if (!programmesArray) {
      return
    }

    const index = programmesArray.findIndex(
      event => event.id === programmeDetailsData.id
    )
    if (index === -1) {
      return
    }

    const links = {}
    if (index > 0) {
      links.previousEventLink = `/programmes/${programmesArray[index - 1].slug}`
    } else {
      links.previousEventLink = "#"
    }
    if (index < programmesArray.length - 1) {
      links.nextEventLink = `/programmes/${programmesArray[index + 1].slug}`
    } else {
      links.nextEventLink = "#"
    }
    this.setState(links)
  }
  render() {
    const { t } = this.props

    return (
      <Layout>
        <div>
          <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:grid-cols-3 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
            <div className="col-span-4 lg:col-span-1 row-span-1 uppercase w-full lg:w-auto lg:order-2">
              <div className="text-center inline-block bg-white">
                <span className="text-2xl">&lt;</span>
                <span className="tracking-title-3 text-base ml-3 mr-2">
                  {/* Title is the formated event of the date */}
                  {this.getTitle()}
                </span>
                <span className="text-2xl">&gt;</span>
              </div>
            </div>

            <div className="lg:order-1 inline-block">
              <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                {/* Link to the previous event */}
                <Link to={this.state.previousEventLink}>
                  {t("title_event_previous_programme")}
                </Link>
              </div>
            </div>

            <div className="lg:hidden separator inline-block align-middle mx-2"></div>

            <div className="lg:order-3 inline-block">
              <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                {/* Link to the next event */}
                <Link to={this.state.nextEventLink}>
                  {t("title_event_next_programme")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <EventHeroComponent
          sliderItems={this.state.programmeDetailsData.sliderImages}
          pageData={this.state.programmeDetailsData}
        />

        <LinksList
          title={t("lable_links")}
          list={this.state.programmeDetailsData.links}
        ></LinksList>
        <LinksList
          title={t("lable_explore")}
          list={this.state.programmeDetailsData.explore}
        ></LinksList>
      </Layout>
    )
  }
}

export default withTrans(ProgrammeDetails)
